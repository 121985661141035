import React, {useState} from "react";
import {getConfig} from "../config";
import {useAuth0} from "@auth0/auth0-react";
import {Alert, Button, Spinner} from "reactstrap";

function AddBalance({show, setShow}) {
    const [data, setData] = useState({
        mealType: "",
        vendor: "",
        amount: 0.00
    })
    const onChange = (evt) => {
        const key = evt.target.name;
        const value = evt.target.value;
        setData((oldData) => ({...oldData, [key]: value}));
    }

    const [submitIsOngoing, setSubmitIsOngoing] = useState(false)

    const callApi = async () => {
        try {
            const token = await getAccessTokenSilently();

            const date = new Date()
            const randomSuffix = String(Math.floor(1000 + Math.random() * 9000))

            setSubmitIsOngoing(true);

            const response = await fetch(apiOrigin, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors",
                body: JSON.stringify({token,
                    command: "writeTransaction",
                    ...data,
                    datetime: `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, '0')}${String(date.getDate()).padStart(2, '0')}.${String(date.getHours()).padStart(2, '0')}${String(date.getMinutes()).padStart(2, '0')}${String(date.getSeconds()).padStart(2, '0')}${String(date.getMilliseconds()).padStart(3,'0')}${randomSuffix}`
                }),
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const responseData = await response.json();

            console.log("responseData", responseData);

            setSubmitIsOngoing(false);

            setState({
                ...state,
                showResult: true,
                apiMessage: responseData,
            });

            setData({
                mealType: "",
                vendor: "",
                amount: 0.00
            })

            setShow(!show);

        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
            console.log(error);
        }
    };

    const onSubmit = (evt) => {
        callApi();

        evt.preventDefault();
    }

    const { apiOrigin = "https://1wkx7ndztc.execute-api.us-east-1.amazonaws.com/v1/record", audience } = getConfig();

    const [state, setState] = useState({
        apiMessage: "",
        error: null,
    });

    const {
        getAccessTokenSilently,
        loginWithPopup,
        getAccessTokenWithPopup,
    } = useAuth0();

    const handleConsent = async () => {
        try {
            await getAccessTokenWithPopup();
            setState({
                ...state,
                error: null,
            });
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }

        await callApi();
    };

    const handleLoginAgain = async () => {
        try {
            await loginWithPopup();
            setState({
                ...state,
                error: null,
            });
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }

        await callApi();
    };

    const handle = (e, fn) => {
        e.preventDefault();
        fn();
    };

    return (
        <>
            {state.error === "consent_required" && (
                <Alert color="warning">
                    You need to{" "}
                    <a
                        href="#/"
                        class="alert-link"
                        onClick={(e) => handle(e, handleConsent)}
                    >
                        consent to get access to users api
                    </a>
                </Alert>
            )}

            {state.error === "login_required" && (
                <Alert color="warning">
                    You need to{" "}
                    <a
                        href="#/"
                        class="alert-link"
                        onClick={(e) => handle(e, handleLoginAgain)}
                    >
                        log in again
                    </a>
                </Alert>
            )}

            <div className={`Modal ${show ? "Show" : ""}`}>
                <div className="container">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Add a Purchase</h5>
                            </div>
                            <div className="modal-body">
                                <div className="action-sheet-content">
                                    <form onSubmit={onSubmit}>
                                        <div className="form-group basic">
                                            <div className="input-wrapper">
                                                <label className="label" htmlFor="mealType">Meal Type</label>
                                                <select className="form-control custom-select" name="mealType" onChange={onChange}>
                                                    <option value="Dinner">Dinner</option>
                                                    <option value="Lunch">Lunch</option>
                                                    <option value="Breakfast">Breakfast</option>
                                                    <option value="Snack">Snack</option>
                                                    <option value="Coffee">Coffee</option>
                                                    <option value="Date Night">Date Night</option>
                                                </select>
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <label className="label">Enter Vendor</label>
                                            <div className="input-group mb-3">
                                                <input type="text" name="vendor" onChange={onChange}
                                                       className="form-control form-control-lg"/>
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <label className="label">Enter Amount</label>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="input1">$</span>
                                                </div>
                                                <input type="text" name="amount" onChange={onChange}
                                                       className="form-control form-control-lg"/>
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <Button
                                                    className="btn-c btn-primary btn-block btn-lg"
                                                    onClick={onSubmit} disabled={!audience && !submitIsOngoing}>

                                                {submitIsOngoing && (
                                                    <><Spinner size="sm">Saving Data</Spinner></>
                                                )}
                                                {!submitIsOngoing && `Submit`}
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddBalance