import React, {useState} from "react";
import {getConfig} from "../config";
import {useAuth0} from "@auth0/auth0-react";
import {Alert, Button, Spinner} from "reactstrap";

function AdjustDailyLimit({show, setShow, userData}) {
    const [data, setData] = useState({
        amount: userData.unformattedLimitAmount,
    })
    const onChange = (evt) => {
        const key = evt.target.name;
        const value = evt.target.value;
        setData((oldData) => ({...oldData, [key]: value}));
    }

    const [submitIsOngoing, setSubmitIsOngoing] = useState(false)

    const callApi = async () => {
        try {
            const token = await getAccessTokenSilently();

            const date = new Date()
            const randomSuffix = String(Math.floor(1000 + Math.random() * 9000))

            setSubmitIsOngoing(true);

            const response = await fetch(apiOrigin, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors",
                body: JSON.stringify({token,
                    command: "writeLimit",
                    ...data,
                }),
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const responseData = await response.json();

            console.log("responseData", responseData);

            setSubmitIsOngoing(false);

            setState({
                ...state,
                showResult: true,
                apiMessage: responseData,
            });

            setData({
                amount: 35.00
            })

            setShow(!show);

        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
            console.log(error);
        }
    };

    const onSubmit = (evt) => {
        callApi();

        evt.preventDefault();
    }

    const { apiOrigin = "https://1wkx7ndztc.execute-api.us-east-1.amazonaws.com/v1/record", audience } = getConfig();

    const [state, setState] = useState({
        apiMessage: "",
        error: null,
    });

    const {
        getAccessTokenSilently,
        loginWithPopup,
        getAccessTokenWithPopup,
    } = useAuth0();

    const handleConsent = async () => {
        try {
            await getAccessTokenWithPopup();
            setState({
                ...state,
                error: null,
            });
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }

        await callApi();
    };

    const handleLoginAgain = async () => {
        try {
            await loginWithPopup();
            setState({
                ...state,
                error: null,
            });
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }

        await callApi();
    };

    const handle = (e, fn) => {
        e.preventDefault();
        fn();
    };

    return (
        <>
            {state.error === "consent_required" && (
                <Alert color="warning">
                    You need to{" "}
                    <a
                        href="#/"
                        class="alert-link"
                        onClick={(e) => handle(e, handleConsent)}
                    >
                        consent to get access to users api
                    </a>
                </Alert>
            )}

            {state.error === "login_required" && (
                <Alert color="warning">
                    You need to{" "}
                    <a
                        href="#/"
                        class="alert-link"
                        onClick={(e) => handle(e, handleLoginAgain)}
                    >
                        log in again
                    </a>
                </Alert>
            )}

            <div className={`Modal ${show ? "Show" : ""}`}>
                <div className="container">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">Set Daily Limit</h5>
                            </div>
                            <div className="modal-body">
                                <div className="action-sheet-content">
                                    <form onSubmit={onSubmit}>

                                        <div className="form-group basic">
                                            <label className="label">Enter Amount</label>
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="input1">$</span>
                                                </div>
                                                <input type="text" name="amount" onChange={onChange}
                                                       className="form-control form-control-lg"
                                                        defaultValue={userData.unformattedLimitAmount}
                                                />
                                            </div>
                                        </div>

                                        <div className="form-group basic">
                                            <Button
                                                    className="btn-c btn-primary btn-block btn-lg"
                                                    onClick={onSubmit} disabled={!audience && !submitIsOngoing}>

                                                {submitIsOngoing && (
                                                    <><Spinner size="sm">Saving Data</Spinner></>
                                                )}
                                                {!submitIsOngoing && `Submit`}
                                            </Button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdjustDailyLimit