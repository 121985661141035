import React, {Component, useState, useEffect} from "react";
import {Link} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import Loading from "./Loading";
import AddBalance from "./AddBalance";
import { CircularProgressbar , buildStyles} from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import {
    Button,
} from "reactstrap";
import ExternalApi from "./ExternalApi";
import AdjustDailyLimit from "./AdjustDailyLimit";
import {ExternalApiGetDataComponent} from "./ExternalApiGetData";
import {getConfig} from "../config";
import transaction from "./Transaction";


const Home = () => {
    const {
        isLoading,
        error,
        user,
        isAuthenticated,
        loginWithRedirect,
        logout
    } = useAuth0();

    const [showAddBalance, setShowAddBalance] = useState(false)
    const [showAdjustDailyLimit, setShowAdjustDailyLimit] = useState(false)
    const [userDataCallLoading, setUserDataCallLoading] = useState(true)

    const [getUserApiState, setGetUserApiState] = useState({
        limitAmount: "$35.00",
        unformattedLimitAmount: 35,
        transactions: [],
        message: "",
        todayTotal: 0.00,
        todayPct: 0,
        yesterdayTotal: 0.00,
        yesterdayPct: 0,
        thisWeekTotal: 0.00,
        thisWeekPct: 0,
        lastWeekTotal: 0.00,
        lastWeekPct: 0,
    })

    const USDollar = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    const callApi = async () => {
        try {

            // TODO: Fix this so it doesn't block permitting access to the API on the first call. 20231025 BMD
            setUserDataCallLoading(true);

            const token = await getAccessTokenSilently();

            const date = new Date(new Date() - 1000 * 60 * 60 * 24 * 14)

            const response = await fetch(apiOrigin, {
                method: "POST", // *GET, POST, PUT, DELETE, etc.
                mode: "cors",
                body: JSON.stringify({token,
                    command: "getUserData",
                    period: `${date.getFullYear()}${String(date.getMonth() + 1).padStart(2, '0')}${String(date.getDate()).padStart(2, '0')}.${String(date.getHours()).padStart(2, '0')}${String(date.getMinutes()).padStart(2, '0')}${String(date.getSeconds()).padStart(2, '0')}${String(date.getMilliseconds()).padStart(3,'0')}`
                }),
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const responseData = await response.json();

            // console.log("responseData", responseData);

            setGetUserApiState({
                limitAmount: USDollar.format(responseData.limitAmount * 1),
                unformattedLimitAmount: responseData.limitAmount,
                transactions: responseData.transactions,
                message: responseData.message,
                todayTotal: USDollar.format(sumTodayTransactions(responseData.transactions)),
                todayPct: Math.ceil(sumTodayTransactions(responseData.transactions) / responseData.limitAmount * 100),
                yesterdayTotal: USDollar.format(sumYesterdayTransactions(responseData.transactions)),
                yesterdayPct: Math.ceil(sumYesterdayTransactions(responseData.transactions) / responseData.limitAmount * 100),
                thisWeekTotal: USDollar.format(sumThisWeekTransactions(responseData.transactions)),
                thisWeekPct: Math.ceil(sumThisWeekTransactions(responseData.transactions) / responseData.limitAmount * 100),
                lastWeekTotal: USDollar.format(sumLastWeekTransactions(responseData.transactions)),
                lastWeekPct: Math.ceil(sumLastWeekTransactions(responseData.transactions) / responseData.limitAmount * 100),
            });

            setUserDataCallLoading(false)

        } catch (error) {
            setGetUserApiState({
                ...getUserApiState,
                error: error.error,
            });
            console.log(error);
        }
    };

    function sumTransactions(transactions, startDaysAgo, endDaysAgo) {
        // 1. Determine the date for "yesterday"
        const start = new Date();
        start.setDate(start.getDate() - startDaysAgo);
        const end = new Date();
        end.setDate(end.getDate() - endDaysAgo);

        const startDate = parseFloat(`${start.getFullYear()}${String(start.getMonth() + 1).padStart(2, '0')}${String(start.getDate()).padStart(2, '0')}`);
        const endDate = parseFloat(`${end.getFullYear()}${String(end.getMonth() + 1).padStart(2, '0')}${String(end.getDate()).padStart(2, '0')}`);

        // 2. Filter and 3. Sum
        return transactions.reduce((total, transaction) => {
            if (transaction.date >= startDate && transaction.date < Math.ceil(endDate) ) {
                total += transaction.amount;
            }
            return total;
        }, 0);
    }

    function sumTodayTransactions(transactions) {
        return sumTransactions(transactions, 1, 0)
    }

    function sumYesterdayTransactions(transactions) {
        return sumTransactions(transactions, 2, 1)
    }

    function sumThisWeekTransactions(transactions) {
        return sumTransactions(transactions, 7, 0)
    }

    function sumLastWeekTransactions(transactions) {
        return sumTransactions(transactions, 14, 7)
    }

    const { apiOrigin = "https://1wkx7ndztc.execute-api.us-east-1.amazonaws.com/v1/record", audience } = getConfig();

    const [state, setState] = useState({
        apiMessage: "",
        error: null,
    });

    const {
        getAccessTokenSilently,
        loginWithPopup,
        getAccessTokenWithPopup,
    } = useAuth0();

    const handleConsent = async () => {
        try {
            await getAccessTokenWithPopup();
            setState({
                ...state,
                error: null,
            });
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }

        await callApi();
    };

    const handleLoginAgain = async () => {
        try {
            await loginWithPopup();
            setState({
                ...state,
                error: null,
            });
        } catch (error) {
            setState({
                ...state,
                error: error.error,
            });
        }

        await callApi();
    };

    const handle = (e, fn) => {
        e.preventDefault();
        fn();
    };

    const logoutWithRedirect = () =>
        logout({
            logoutParams: {
                returnTo: window.location.origin,
            }
        });

    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    if (isLoading) {
        return <Loading/>;
    }

    return (
        <>
            {/* Navigation Bar Imported from Layouts */}
            {/*<Navbar/>*/}

            {/* Auto Notification Imported from Layout */}
            {/*<AutoNotification />*/}

            {/* Header Starts */}
            <div className="header-area" style={{backgroundColor: "#4F5259"}}>
                <div className="container">
                    <div className="row">
                        <div className="col-sm-4 col-3">
                        </div>
                        <div style={{color: 'white'}} className="col-sm-4 col-4 text-center">
                            <Link to={'/'} className="logo">
                                Food <img color="white" width="30px" height="30px"
                                          src={process.env.PUBLIC_URL + '/assets/img/icon/dish.svg'} alt="logo"/> Budget
                            </Link>
                        </div>
                        <div className="col-sm-4 col-5 text-right">
                            <ul className="header-right">
                                <li>
                                    {isAuthenticated && (
                                        <img
                                            src={user.picture}
                                            alt="Profile"
                                            width="35px"
                                            onClick={() => logoutWithRedirect()}
                                        />
                                    )
                                    }
                                    {!isAuthenticated && (
                                        <Button color="primary" className="btn-margin"
                                                onClick={() => loginWithRedirect()}>Signup / Login</Button>
                                    )
                                    }
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* Header Ends */}
            <div className={`Overlay ${showAddBalance ? 'Show' : ''}`} onClick={() => setShowAddBalance()}></div>
            <AddBalance show={showAddBalance} setShow={setShowAddBalance}/>

            <div className={`Overlay ${showAdjustDailyLimit ? 'Show' : ''}`} onClick={() => setShowAdjustDailyLimit()}></div>
            <AdjustDailyLimit show={showAdjustDailyLimit} setShow={setShowAdjustDailyLimit} userData={getUserApiState}/>
            {/* Navbar Ends */}

            {!isAuthenticated &&
                <div className="balance-area pd-top-40 mg-top-50" onClick={() => loginWithRedirect()}>
                    <div className="container">
                        <div className="balance-area-bg balance-area-bg-home">
                            <div className="balance-title text-center">
                                <h6>Welcome!</h6>
                            </div>
                            <div className="ba-balance-inner text-center" style={{backgroundColor: '#5A86BF'}}>
                                <div className="icon">
                                    <img src={process.env.PUBLIC_URL + '/assets/img/icon/piggy-bank.svg'} alt="img"/>
                                </div>
                                <h5 className="title">Please click here or above to login/register.</h5>
                            </div>
                        </div>
                    </div>
                </div>
            }

            {isAuthenticated && (<>
                <div className="balance-area pd-top-40 mg-top-50" onLoad={callApi}>
                    <div className="container">
                        <div className="balance-area-bg balance-area-bg-home">
                            <div className="balance-title text-center">
                                <h6>Welcome, {user.name}!</h6>
                            </div>
                            <div className="ba-balance-inner text-center" style={{backgroundColor: '#5A86BF'}}>
                                <div className="icon">
                                    <img src={process.env.PUBLIC_URL + '/assets/img/icon/piggy-bank.svg'} alt="img"/>
                                </div>
                                <h5 className="title">Daily Spending</h5>
                                <h5 className="amount">
                                    <span
                                        style={sumTransactions(getUserApiState.transactions, 1, 0) > getUserApiState.unformattedLimitAmount ? {color: "red"} : {}}
                                    >
                                        {USDollar.format(sumTransactions(getUserApiState.transactions, 1, 0))}
                                    </span>
                                    &nbsp;/ {getUserApiState.limitAmount}</h5>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="add-balance-area pd-top-40">
                    <div className="container">
                        <div className="ba-add-balance-title ba-add-balance-btn" onClick={() => setShowAddBalance(!showAddBalance)}>
                            <h5>Add a Purchase</h5>
                            <i className="fa fa-plus"/>
                        </div>
                        <div className="ba-add-balance-inner mg-top-40">
                            <div className="row custom-gutters-20">
                                <div className="col-6">
                                    <div className="btn btn-blue ba-add-balance-btn" onClick={() => setShowAdjustDailyLimit(!showAdjustDailyLimit)}>
                                        Adjust Daily Limit
                                        <i className="fa fa-sliders"/>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="btn btn-red ba-add-balance-btn">
                                        Historical Trend
                                        <i className="fa fa-arrow-up"/>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="goal-area pd-top-36">
                    <div className="container">
                        <div className="section-title">
                            <h3 className="title">Saving Goals</h3>
                        </div>
                        <div className="single-goal single-goal-one">
                            <div className="row">
                                <div className="col-9 pr-0">
                                    <div className="details">
                                        <h6>Today</h6>
                                        <p>{new Date().toLocaleDateString()}</p>
                                    </div>
                                    <h6 className="goal-amount">
                                        {getUserApiState.todayTotal}
                                    </h6>
                                </div>
                                <div className="col-3 pl-0 single-goal-border">

                                    <div className="circle-inner circle-inner-one">

                                        <div style={{ width: 75, height: 75 }}>
                                            <CircularProgressbar value={getUserApiState.todayPct} text={`${getUserApiState.todayPct}%`} styles={buildStyles({
                                                pathColor: '#A9CBD9',
                                                textColor: '#A9CBD9',
                                                pathTransitionDuration: 5
                                            })}/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="single-goal single-goal-two">
                            <div className="row">
                                <div className="col-9 pr-0">
                                    <div className="details">
                                        <h6>Yesterday</h6>
                                        <p>{new Date((new Date())-24*3600*1000).toLocaleDateString()}</p>
                                    </div>
                                    <h6 className="goal-amount">
                                        {getUserApiState.yesterdayTotal}
                                    </h6>
                                </div>
                                <div className="col-3 pl-0 single-goal-border">
                                    <div className="circle-inner circle-inner-two">

                                        <div style={{ width: 75, height: 75 }}>
                                            <CircularProgressbar value={getUserApiState.yesterdayPct} text={`${getUserApiState.yesterdayPct}%`} styles={buildStyles({
                                                pathColor: '#5A86BF',
                                                textColor: '#5A86BF',
                                                pathTransitionDuration: 5
                                            })}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="single-goal single-goal-three">
                            <div className="row">
                                <div className="col-9 pr-0">
                                    <div className="details">
                                        <h6>This Week</h6>
                                        <p>Week of {new Date((new Date())-7*24*3600*1000).toLocaleDateString()}</p>
                                    </div>
                                    <h6 className="goal-amount">
                                        {getUserApiState.thisWeekTotal}
                                    </h6>
                                </div>
                                <div className="col-3 pl-0 single-goal-border">
                                    <div className="circle-inner circle-inner-three">

                                        <div style={{ width: 75, height: 75 }}>
                                            <CircularProgressbar value={getUserApiState.thisWeekPct} text={`${getUserApiState.thisWeekPct}%`} styles={buildStyles({
                                                pathColor: '#4F5259',
                                                textColor: '#4F5259',
                                                pathTransitionDuration: 5
                                            })}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="single-goal single-goal-four">
                            <div className="row">
                                <div className="col-9 pr-0">
                                    <div className="details">
                                        <h6>Last Week</h6>
                                        <p>Week of {new Date((new Date())-14*24*3600*1000).toLocaleDateString()}</p>
                                    </div>
                                    <h6 className="goal-amount">
                                        {getUserApiState.lastWeekTotal}
                                    </h6>
                                </div>
                                <div className="col-3 pl-0 single-goal-border">
                                    <div className="circle-inner circle-inner-four">

                                        <div style={{ width: 75, height: 75 }}>
                                            <CircularProgressbar value={getUserApiState.lastWeekPct} text={`${getUserApiState.lastWeekPct}%`} styles={buildStyles({
                                                pathColor: '#D9D0C1',
                                                textColor: '#D9D0C1',
                                                pathTransitionDuration: 5
                                            })}/>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>)}

            {/* Footer Component Imported from Layouts */}
            {/*<Footer />*/}
        </>
    );
}

export default Home;